import { Skeleton, useMediaQuery } from '@mui/material';
import Loader from '../../../../components/loader';
import styles from './productSkeleton.module.sass';
import Button from '../../../../components/button';

export const ProductTableSkeleton = ({ number, handleStopSearch }) => {
  const isMobile = useMediaQuery('(max-width: 425px)');

  return (
    <>
      {!isMobile ? (
        <>
          <tr className="tw-bg-white tw-border-b tw-border-t tw-border-[#EEEEEE]">
            <td className="tw-pl-3">
              {number ? (
                <>
                  <p className="tw-min-w-[30px] tw-text-[#7F7F7F] tw-text-xs tw-leading-[16px]">{number}</p>
                </>
              ) : (
                <>
                  <Skeleton variant="rectangular" width={15} height={16} className="tw-rounded-[4px]" />
                </>
              )}
            </td>
            <td className="tw-pr-[10px] tw-py-7 tw-max-w-[343px] projectTablet:tw-w-[242px]">
              <Skeleton variant="rectangular" height={14} className="tw-rounded-lg" />
            </td>
            <td className="projectMd:tw-relative tw-flex tw-flex-row tw-justify-center tw-h-full tw-items-center tw-pt-[15px]">
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-1 projectMd:tw-absolute projectTablet:tw-static tw-top-[13px] tw-w-[160px] tw-left-[-83px]">
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
                  <Loader className={`${styles.loaderTable} tw-w-fit`} />
                  <p className="tw-text-[#191919] tw-text-xs tw-font-medium tw-leading-4">Ведется поиск товара</p>
                </div>
                <Button
                  variant="text"
                  onClick={handleStopSearch}
                  className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-semibold"
                >
                  Остановить поиск
                </Button>
              </div>
            </td>
            <td className="projectTablet:tw-hidden">
              <div className="tw-flex tw-flex-row tw-justify-center tw-pl-[22px]">
                <Skeleton variant="rectangular" width={63} height={14} className="tw-rounded-lg " />
              </div>
            </td>
            <td>
              <div className="tw-flex tw-flex-row tw-justify-center">
                <Skeleton variant="rectangular" width={63} height={14} className="tw-rounded-lg " />
              </div>
            </td>
            <td className="tw-pl-[14px]">
              <div className="tw-flex tw-flex-row tw-gap-4 tw-items-baseline">
                <Skeleton variant="rectangular" width={32} height={28} className="tw-rounded-lg " />
                <Skeleton variant="rectangular" width={24} height={24} className="tw-rounded-lg " />
              </div>
            </td>
          </tr>
        </>
      ) : (
        <div className="tw-flex tw-flex-col tw-bg-white tw-w-full tw-pt-3 tw-pb-[19px] tw-px-2">
          <div className="tw-flex tw-flex-row tw-justify-between">
            {number ? (
              <>
                <p className="tw-min-w-[30px] tw-text-[#134B98] tw-text-sm tw-leading-[16px] tw-font-medium">
                  {number}
                </p>
              </>
            ) : (
              <>
                <Skeleton variant="rectangular" width={15} height={16} className="tw-rounded-[4px]" />
              </>
            )}
            <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
          </div>
          <Skeleton variant="rectangular" width={238} height={14} className="tw-rounded-[4px]" />
          <div className="tw-flex tw-flex-row tw-gap-5 tw-items-center tw-mt-5">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
              <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
              <Skeleton variant="rectangular" width={25} height={14} className="tw-rounded-[77px]" />
              <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
            </div>
            <Skeleton variant="rectangular" width={121} height={14} className="tw-rounded-[77px]" />
          </div>
          <div className="tw-flex tw-flex-row tw-justify-center tw-mt-[21px]">
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-w-[160px] ">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-[6px]">
                <Loader className={`${styles.loaderTable} tw-w-fit`} />
                <p className="tw-text-[#191919] tw-text-xs tw-font-medium tw-leading-4">Ведется поиск товара</p>
              </div>
              <Button
                variant="text"
                onClick={handleStopSearch}
                className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#134B98] tw-text-sm tw-leading-[18px] tw-font-semibold"
              >
                Остановить поиск
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
