import { useCallback, useEffect, useState } from 'react';
import { usePooling } from '../../hooks/usePooling';
import { organizationsAPIs } from '../../services';
import { useSelector } from 'react-redux';
import { checkIsAdmin } from '../../utils/common';
import classNames from 'classnames';

export const RequestLimitNotification = ({ stopCallback, isSearching, containerClassName }) => {
  const [limits, setLimits] = useState({
    reached: null,
    maxLimit: null,
  });
  const [showWarning, setShowWarning] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);

  const fetchOrg = useCallback(async () => {
    const organization_id = localStorage.getItem('organizationId');

    try {
      if (isAdmin) {
        const res = await organizationsAPIs.getAdminOrganization(organization_id);
        return res;
      } else {
        const res = await organizationsAPIs.getUserOrganization(organization_id);
        return res;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [isAdmin]);

  const { data, startUpdate } = usePooling(fetchOrg, 15000);

  const checkLimits = useCallback((reached, limit) => {
    if (reached >= limit / 2) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, []);

  //initial limits
  useEffect(() => {
    const fetchInitialLimits = async () => {
      try {
        const res = await fetchOrg();
        setLimits({
          reached: res.count_scan_in_month,
          maxLimit: res.scan_limit_max,
        });
        checkLimits(res.count_scan_in_month, res.scan_limit_max);
      } catch (err) {
        console.error(err);
      }
    };
    fetchInitialLimits();
  }, [fetchOrg, checkLimits]);

  useEffect(() => {
    startUpdate();
  }, [startUpdate]);

  useEffect(() => {
    if (data) {
      const { count_scan_in_month, scan_limit_max } = data;
      setLimits({
        reached: count_scan_in_month,
        maxLimit: scan_limit_max,
      });

      if (count_scan_in_month !== undefined && scan_limit_max !== undefined) {
        if (count_scan_in_month === scan_limit_max && isSearching) {
          stopCallback && stopCallback();
        }
      }
      checkLimits(count_scan_in_month, scan_limit_max);
    }
  }, [data, isSearching, stopCallback, checkLimits]);
  return (
    <>
      {showWarning && (
        <div
          className={classNames(
            'tw-flex tw-flex-row tw-py-3 tw-px-6 tw-bg-[#FFEAEA] tw-w-full tw-rounded-lg',
            containerClassName
          )}
        >
          <p className="tw-text-[#FF4343] tw-leading-[18px] tw-font-medium tw-text-sm">
            {limits.reached === limits.maxLimit
              ? 'Вы достигли лимита запросов на товары в месяц.'
              : 'Вы приближаетесь к лимиту запросов на товары в месяц.'}{' '}
            Использовано {limits.reached} из {limits.maxLimit}
          </p>
        </div>
      )}
    </>
  );
};
