import { Skeleton, useMediaQuery } from '@mui/material';
import Loader from '../../../../components/loader';
import Button from '../../../../components/button';

export const ProductSkeleton = ({ handleStopSearching, number }) => {
  const isMobile = useMediaQuery('(max-width:425px)');

  return (
    <>
      {!isMobile ? (
        <div className="tw-bg-white tw-relative tw-flex tw-flex-col tw-rounded-lg tw-divide-x tw-divide-[#D9D9D9]">
          {/* {number && (
        <span className="tw-absolute tw-top-0 tw-left-0 tw-border-[#D5D5D5] tw-border tw-bg-white tw-rounded-tl-lg tw-rounded-br-lg tw-text-sm tw-leading-[18px] tw-text-center tw-min-h-[31px] tw-min-w-[31px] tw-py-[6px] tw-z-[2]">
          {number}
        </span>
      )} */}
          <div className="tw-flex tw-flex-row tw-divide-x tw-divide-[#D9D9D9]">
            <div className="tw-flex tw-flex-row tw-p-4 tw-w-[70%] tw-relative">
              <Skeleton
                variant="rectangular"
                className="tw-absolute tw-top-[10px] tw-right-[6px] tw-h-[28px] tw-w-[28px] tw-rounded-[4px]"
              />
              <Skeleton variant="rectangular" className=" tw-rounded-[4px] tw-min-w-[120px]" height={120} width={120} />
              <div className="tw-flex-tw-flex-col tw-w-full tw-ml-[14px] tw-gap-3 tw-relative">
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-max-w-[379px]">
                  <Skeleton variant="rectangular" className="tw-w-[378px] tw-h-[14px] tw-rounded-[77px]" />
                  <Skeleton variant="rectangular" className="tw-w-[197px] tw-h-[14px] tw-rounded-[77px]" />
                  <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mt-1">
                    <div className="tw-flex tw-flex-row tw-gap-3 tw-items-center">
                      <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
                      <Skeleton variant="rectangular" width={25} height={14} className="tw-rounded-[77px]" />
                      <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
                    </div>
                  </div>
                </div>
                {handleStopSearching && (
                  <div className="tw-flex tw-flex-col tw-items-center tw-gap-[6px] tw-absolute tw-top-[27px] tw-left-[50%]">
                    <Loader iconClassName="tw-w-6 tw-h-6" />
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
                      Ведется поиск товара
                    </p>
                    <Button
                      onClick={handleStopSearching}
                      variant="text"
                      className="tw-text-[#134B98] tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-sm tw-leading-[18px] tw-font-semibold"
                    >
                      Остановить поиск
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-pt-4 tw-pl-4 tw-pr-[14px] tw-pb-[14px] tw-w-[30%] tw-relative">
              <Skeleton
                variant="rectangular"
                className="tw-absolute tw-top-[13px] tw-right-[18px]"
                height={28}
                width={28}
              />
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px] tw-mb-[17px]">
                <Skeleton variant="rectangular" width={176} height={14} className="tw-rounded-[77px]" />
                <Skeleton variant="rectangular" width={109} height={14} className="tw-rounded-[77px]" />
              </div>
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-[5px] tw-mb-4">
                <Skeleton variant="rectangular" width={82} height={14} className="tw-rounded-[77px]" />
                <Skeleton variant="rectangular" width={143} height={14} className="tw-rounded-[77px]" />
              </div>
              <Skeleton variant="rectangular" width={123} height={38} className="tw-rounded-lg" />
            </div>
          </div>
        </div>
      ) : (
        <div className="tw-bg-white tw-rounded-lg tw-pt-3 tw-px-2 tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row tw-justify-between tw-gap-4 tw-mb-4">
            <div className="tw-flex tw-flex-row tw-gap-3">
              <Skeleton variant="rectangular" width={76} height={76} className="tw-rounded-[4px]" />
              <Skeleton variant="rectangular" width={138} height={14} className="tw-rounded-[77px]" />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-[10px]">
              <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
              <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-mb-[18px]">
            <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
            <Skeleton variant="rectangular" width={25} height={14} className="tw-rounded-[77px] tw-mx-3" />
            <Skeleton variant="rectangular" width={28} height={28} className="tw-rounded-[4px]" />
            <Skeleton variant="rectangular" width={105} height={14} className="tw-rounded-[77px] tw-ml-4" />
          </div>
          <Skeleton variant="rectangular" width={105} height={14} className="tw-rounded-[77px] tw-mb-5" />
          <Skeleton variant="rectangular" width={121} height={14} className="tw-rounded-[77px] tw-mb-[23px]" />
          {handleStopSearching && (
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-[6px] tw-pb-[44px]">
              <Loader iconClassName="tw-w-6 tw-h-6" />
              <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Ведется поиск товара</p>
              <Button
                onClick={handleStopSearching}
                variant="text"
                className="tw-text-[#134B98] tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-sm tw-leading-[18px] tw-font-semibold"
              >
                Остановить поиск
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
