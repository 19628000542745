import { MenuItem, Select, useMediaQuery } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../../../../icons/arrowDownIcon.svg';
import { useCallback } from 'react';
import { useUrlState } from '../../../../hooks/useUrlState';
import { FILTER_PRESET_LABELS, FILTER_PRESETS } from '../../../../utils/constant';
import { useDialog } from '../../../../providers/dialog.provider';
import { ProductFilterMobile } from './ProductFilterMobile';
import Button from '../../../../components/button';

const sx = {
  fieldset: {
    borderColor: 'transparent',
  },
  svg: {
    top: 'calc(50% - 0.9em)',
    transition: 'all 0.2s linear',
  },
  '&.MuiInputBase-root': {
    '& .MuiSelect-select': {
      padding: '13px 36px 13px 14px',
    },
  },
};

export const ProductFilter = () => {
  const { getQueryParam, setQueryParam } = useUrlState();
  const laptop = useMediaQuery('(max-width: 1256px)');

  const dialog = useDialog();
  const handleSelectFilterChange = useCallback(
    (option) => {
      setQueryParam('filter_preset', option);
    },
    [setQueryParam]
  );

  const getFilerPresetName = useCallback(() => {
    const presetName = getQueryParam('filter_preset');

    if (!presetName) {
      return FILTER_PRESET_LABELS.CREATED_DESC;
    }

    return presetName;
  }, [getQueryParam]);

  const openModalMenu = useCallback(() => {
    dialog.open(<ProductFilterMobile defaultValue={getFilerPresetName} onChange={handleSelectFilterChange} />);
  }, [dialog, getFilerPresetName, handleSelectFilterChange]);

  return (
    <div className="tw-flex tw-flex-row tw-gap-[25px] tw-items-center projectMobile:tw-w-full">
      {laptop ? (
        <Button onClick={openModalMenu} className='tw-flex tw-flex-row tw-bg-white tw-py-[11px] tw-pl-[14px] tw-pr-[10px] tw-rounded-lg tw-shadow-none tw-border-[#D5D5D5] tw-border projectMobile:tw-w-full projectMobile:tw-justify-between'>
          <p className='tw-text-[#191919] tw-text-sm tw-font-normal tw-leading-[18px]'>{getFilerPresetName()}</p>
          <ArrowIcon />
        </Button>
      ) : (
        <Select
          className="tw-bg-white tw-rounded-lg tw-text-sm tw-leading-[18px] tw-h-[46px]"
          IconComponent={ArrowIcon}
          sx={sx}
          value={getFilerPresetName()}
          onChange={(e) => handleSelectFilterChange(e.target.value)}
        >
          {Object.entries(FILTER_PRESETS).map(([label], index) => (
            <MenuItem key={index} value={label}>
              {label}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
};
