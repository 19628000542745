import { request } from '../utils/request';
import { Api, onQueryStartedErrorHandler } from './api-client';

export const userAPIs = {
  getLimits: () =>
    request('/api/users/limit', {
      method: 'GET',
    }),
  getUsersRequests: (query = '') =>
    request(`/api/admin/users/report?query=${query}`, {
      method: 'GET',
    }),
  sendFeedback: (body) =>
    request(`/api/users/feedback_form`, {
      method: 'POST',
      body: body,
    }),
  getGuide: () =>
    request('/api/organizations/user_guide', {
      method: 'GET',
    }),
  setUserReadGuide: () =>
    request('/api/user_prompts', {
      method: 'GET',
    }),
  getUserReadGuideStatus: () =>
    request('/api/user_prompts/check', {
      method: 'GET',
    }),
  getFaq: () =>
    request('/api/admin/faq', {
      method: 'GET',
    }),
  resetPassword: (body) =>
    request(`/api/users/send_password`, {
      method: 'POST',
      body,
    }),
  changePassword: (token, body) =>
    request(`/api/users/reset_pass/${token}`, {
      method: 'POST',
      body,
    }),
  updateUser: (body) =>
    request('/api/users/current', {
      method: 'PATCH',
      body,
    }),
};

export const newUserAPIs = Api.injectEndpoints({
  endpoints: (builder) => ({
    getFaqs: builder.query({
      query: () => ({
        url: '/api/admin/faq',
        method: 'GET',
      }),
      providesTags: ['FAQ'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при получении FAQ');
      },
    }),
    createFaq: builder.mutation({
      query: (body) => ({
        url: '/api/admin/faq',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FAQ'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при создании FAQ');
      },
    }),
    editFaq: builder.mutation({
      query: ({ id, body }) => ({
        url: `/api/admin/faq/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['FAQ'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при изменении FAQ');
      },
    }),
    deleteFaq: builder.mutation({
      query: (id) => ({
        url: `/api/admin/faq/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FAQ'],
      onQueryStarted: (arg, { queryFulfilled }) => {
        onQueryStartedErrorHandler(queryFulfilled, 'Произошла ошибка при удалении FAQ');
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetFaqsQuery, useCreateFaqMutation, useEditFaqMutation, useDeleteFaqMutation } = newUserAPIs;
