import { Dialog } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import Button from '../../../button';
import classNames from 'classnames';
import styles from './InstructionVideo.module.sass'

const videoSrc = 'https://storage.yandexcloud.net/gosdep-backend/IMG_5584.MOV';

const InstructionsVideo = () => {
  const dialog = useDialog();

  const handleCloseDialog = () => {
    dialog.close();
  };
  return (
    <Dialog open={dialog.visibility}>
      <div className="tw-bg-white tw-rounded-lg tw-px-4 tw-py-6 tw-overflow-hidden tw-h-fit tw-relative">
        <Button
          variant="text"
          className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-top-[10px] tw-right-[10px]"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </Button>
        <div className="tw-w-full">
          <video width="100%" height="100%" controls src={videoSrc} className="tw-mt-5"></video>
        </div>
      </div>
    </Dialog>
  );
};

export const InstructionsVideoThumbnail = () => {
  const dialog = useDialog();

  const handleClick = () => {
    dialog.open(<InstructionsVideo />);
  };
  return (
    <>
      <img src="/images/thumbnail.png" className={classNames("tw-cursor-pointer tw-w-full", styles.video)} onClick={handleClick} alt="thumbnail" />
    </>
  );
};
