import { Dialog, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import { FILTER_PRESETS } from '../../../../utils/constant';

export const ProductFilterMobile = ({ defaultValue, onChange }) => {
  const dialog = useDialog();

  const handleCloseModal = () => {
    dialog.close();
  };

  const handleChange = (event) => {
    onChange(event.target.value);
    handleCloseModal();
  };
  return (
    <Dialog open={dialog.visibility} onClick={handleCloseModal}>
      <div
        className="tw-flex tw-flex-col tw-bg-white tw-rounded-xl tw-px-[14px] tw-py-5"
        onClick={(e) => e.stopPropagation()}
      >
        <RadioGroup defaultValue={defaultValue} name="product-filter" onChange={handleChange}>
          {Object.entries(FILTER_PRESETS).map(([label]) => (
            <FormControlLabel
              value={label}
              className="tw-flex tw-flex-row tw-items-center"
              key={label}
              control={<Radio />}
              label={<p className="tw-text-[#191919] tw-text-sm tw-text-left">{label}</p>}
            />
          ))}
        </RadioGroup>
      </div>
    </Dialog>
  );
};
