import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import Button from '../../components/button';
import GeneralLayout from '../../components/general-layout';
import { ReactComponent as AddIcon } from '../../icons/addProject.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { ReactComponent as ExcelIcon } from '../../icons/excel.svg';
import { getSampleReport } from '../../store/actions/organizations';
import { SearchInput } from '../../components/input/search';
import {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useUpdateProjectMutation,
} from '../../services';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDialog } from '../../providers/dialog.provider';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import { ProjectsFormModal } from './components/ProjectsFormModal';
import { createNotification } from '../../utils/notifications';
import moment from 'moment';
import { Pagination } from '../../components/table/pagination';
import { RequestLimitNotification } from '../../components/request-limit';
import { ReactComponent as LockIcon } from '../../icons/lockIcon.svg';
import { BlockedHostsModal } from '../../components/blocked-hosts/BlockedHostsModal';
import { getProjectStatus } from '../../utils/common';
import { priceFormatter } from '../../utils/priceFormatter';
import { BACKGROUNDCOLOR_BY_STATUS } from '../../utils/constant';
import Table from '../../components/table';
import { useUrlState } from '../../hooks/useUrlState';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import { Tours } from '../../components/tour/Tours';
import styles from './projects.module.sass';
import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';
import { ProjectsMobile } from './components/ProjectsMobileItem';

function ProjectsPage() {
  const history = useHistory();
  const location = useLocation();
  const mobile = useMediaQuery('(max-width: 425px)');

  const dialog = useDialog();

  const organization_id =
    useSelector((store) => store?.organizations?.selected?.id) ?? localStorage.getItem('organizationId');
  const organization_address = useSelector((state) => state?.organizations?.selected?.address);

  const { getQueryParam, setQueryParam } = useUrlState();

  const page_size = Number(getQueryParam('page_size')) || 10;
  const page_num = Number(getQueryParam('page_num')) || 1;
  const query = getQueryParam('query') || '';

  const [updateProject] = useUpdateProjectMutation();
  const [createProject] = useCreateProjectMutation();
  const [deleteProject] = useDeleteProjectMutation();

  const { data, isLoading } = useGetProjectsQuery(
    {
      organization_id: organization_id,
      page_size,
      page_num,
      query,
    },
    {
      skip: !organization_id,
    }
  );

  const goToPage = useCallback(
    (page) => {
      setQueryParam('page_num', page);
    },
    [setQueryParam]
  );

  const handleLimitChange = useCallback(
    (value) => {
      setQueryParam('page_size', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );
  const handleQueryChange = useCallback(
    (value) => {
      setQueryParam('query', value);
      setQueryParam('page_num', 1);
    },
    [setQueryParam]
  );

  const columns = {
    name: {
      label: 'Название',
      renderBodyCell: (value) => {
        return (
          <Link className="tw-text-[#0844B4] hover:tw-text-[#0A50D4]" to={`/projects/${value.id}`}>
            {value.name}
          </Link>
        );
      },
      className: (value) => BACKGROUNDCOLOR_BY_STATUS?.[value?.report_status] ?? '',
    },
    full_name: {
      label: 'ФИО',
      renderBodyCell: (value) => {
        return (
          <p className="tw-text-[#134B98] tw-max-w-[234px] tw-truncate tw-text-sm tw-font-medium tw-leading-[18px]">
            {value?.full_name}
          </p>
        );
      },
      className: (value) => BACKGROUNDCOLOR_BY_STATUS?.[value?.report_status] ?? '',
    },
    created_at: {
      label: 'Дата создания',
      renderBodyCell: (value) => {
        return <>{moment(value.created_at).format('DD.MM.YYYY HH:mm')}</>;
      },
      className: (value) => BACKGROUNDCOLOR_BY_STATUS?.[value?.report_status] ?? '',
    },
    price: {
      label: 'Цена',
      renderBodyCell: (value) => {
        return <>{priceFormatter(value.price, 0)}</>;
      },
      className: (value) => BACKGROUNDCOLOR_BY_STATUS?.[value?.report_status] ?? '',
    },
    report_status: {
      renderHeaderCell: () => {
        return <div className="tw-flex tw-flex-row tw-justify-center">Статус</div>;
      },
      renderBodyCell: getProjectStatus,
      className: (value) => BACKGROUNDCOLOR_BY_STATUS?.[value?.report_status] ?? '',
    },
    product_count: {
      label: 'Позиций',
      className: (value) => BACKGROUNDCOLOR_BY_STATUS?.[value?.report_status] ?? '',
    },
    id: {
      label: '',
      renderBodyCell: (value) => {
        return (
          <div className={`tw-flex tw-flex-row tw-items-center tw-gap-5 tw-justify-end`}>
            <Button
              variant="text"
              className="hover:tw-bg-transparent tw-w-fit tw-p-0 hover:tw-text-[#0844B4]"
              onClick={() => showEditProjectForm(value)}
            >
              <EditIcon className="tw-mr-1" />
            </Button>
            <Button
              variant="text"
              className="hover:tw-bg-transparent tw-w-fit tw-p-0 hover:tw-text-[#0844B4]"
              onClick={() => askConfirmationDelete(value)}
            >
              <DeleteIcon className="tw-mr-1" />
            </Button>
          </div>
        );
      },
      className: (value) => BACKGROUNDCOLOR_BY_STATUS?.[value?.report_status] ?? '',
    },
  };

  const handleCreateProject = useCallback(
    async (project) => {
      await createProject(project)
        .unwrap()
        .then((res) => {
          history.push(`/projects/${res.report.project_id}`);
          dialog.close();
        });
    },
    [createProject, dialog, history]
  );

  const handleEditProject = useCallback(
    async (id, project) => {
      await updateProject({ id, body: project })
        .unwrap()
        .then(() => {
          dialog.close();
          createNotification('Проект изменён');
        });
    },
    [dialog, updateProject]
  );

  const handleDeleteProject = useCallback(
    async (id) => {
      await deleteProject(id)
        .unwrap()
        .then(() => {
          createNotification('Проект удалён');
        });
    },
    [deleteProject]
  );

  const askConfirmationDelete = useCallback(
    (project) => {
      dialog.open(
        <ConfirmModal
          value={project}
          title={`Вы уверены, что хотите удалить проект ${project.name}`}
          onAgree={handleDeleteProject}
        />
      );
    },
    [dialog, handleDeleteProject]
  );

  const showCreateProjectForm = useCallback(() => {
    dialog.open(
      <ProjectsFormModal
        title="Создание проекта"
        value={{
          name: '',
          address: organization_address || '',
        }}
        onSubmit={handleCreateProject}
      />
    );
  }, [dialog, handleCreateProject, organization_address]);

  const showEditProjectForm = useCallback(
    (project) => {
      dialog.open(<ProjectsFormModal value={project} title="Редактирование проекта" onSubmit={handleEditProject} />);
    },

    [dialog, handleEditProject]
  );

  const showBlockedHostsList = useCallback(() => {
    dialog.open(<BlockedHostsModal />);
  }, [dialog]);

  useScrollRestoration([location?.search]);
  return (
    <GeneralLayout bg="white">
      <Tours sectionName="projectsTour" />
      <h1 className="tw-text-[2rem] tw-font-semibold tw-leading-[2.375rem] tw-text-[#191919] tw-mb-8">Проекты</h1>
      <div className="tw-mb-5">
        <RequestLimitNotification />
      </div>
      <div className="tw-bg-white tw-py-[25px] tw-px-[24px] tw-rounded-lg projectMobile:tw-py-4 projectMobile:tw-px-0">
        <header className="tw-flex tw-items-center tw-justify-between tw-mb-5 projectMobile:tw-flex-col projectMobile:tw-justify-start projectMobile:tw-items-start projectMobile:tw-mb-0 projectMobile:tw-px-4">
          <div className="tw-flex tw-flex-row tw-gap-6 projectMobile:tw-w-full">
            <Form
              onSubmit={() => {}}
              initialValues={{
                search: query,
              }}
            >
              {() => (
                <form className="projectMobile:tw-w-full">
                  <SearchInput
                    name="search"
                    placeholder="Найти проект"
                    type="text"
                    onSearchChange={handleQueryChange}
                  />
                </form>
              )}
            </Form>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 projectMobile:tw-flex-col projectMobile:tw-mt-5 projectMobile:tw-items-start">
            <span data-tour="exampleExcelStep">
              <Button
                className="tw-w-fit tw-p-2 tw-flex tw-flex-row tw-items-center tw-gap-[2px] projectMobile:tw-p-0"
                variant="text"
                onClick={() => getSampleReport()}
              >
                <ExcelIcon />
                <p className="tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold">Пример excel файла</p>
              </Button>
            </span>
            <Button
              className="tw-w-fit tw-p-2 tw-flex tw-flex-row tw-items-center tw-gap-[2px] projectMobile:tw-p-0"
              variant="text"
              onClick={() => showBlockedHostsList()}
            >
              <LockIcon />
              <p className="tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold">
                Заблокированные источники
              </p>
            </Button>
            <span data-tour="newProjectStep">
              <Button
                className="tw-px-4 tw-w-fit tw-py-[11px] tw-rounded-lg tw-text-sm tw-leading-[18px] projectMobile:tw-bg-transparent projectMobile:hover:tw-bg-transparent projectMobile:tw-border-none projectMobile:tw-shadow-none projectMobile:tw-text-[#134B98] projectMobile:tw-p-0"
                onClick={() => showCreateProjectForm()}
              >
                <AddIcon
                  className={classNames(
                    'tw-mr-[4px] tw-w-5 tw-h-5 projectMobile:tw-w-6 projectMobile:tw-h-6',
                    styles.icon
                  )}
                />
                Новый проект
              </Button>
            </span>
          </div>
        </header>
        {!isLoading ? (
          <>{!mobile ? <Table rows={data?.data} columns={columns} /> : <ProjectsMobile projects={data?.data} onEdit={showEditProjectForm} onDelete={askConfirmationDelete} />}</>
        ) : (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
            <div
              class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            />
          </div>
        )}
      </div>
      <Pagination
        rowsPerPage={page_size}
        count={data?.count}
        page={data?.page_num}
        onPageChange={goToPage}
        onLimitChange={handleLimitChange}
      />
    </GeneralLayout>
  );
}

export default ProjectsPage;
