import { Tooltip } from '@mui/material';
import styles from './priceGraph.module.sass';
import { ReactComponent as TooltipIcon } from '../../../icons/tooltipIcon.svg';
import { priceFormatter } from '../../../utils/priceFormatter';

export const PriceGraph = ({ minPrice, avgPrice, maxPrice }) => {
  const isValidPrice = (price) => {
    return typeof price === 'number' && price >= 0;
  };
  return (
    <>
      <div className={`tw-w-full ${styles.graph} tw-h-[5px] tw-max-w-[536px] tw-rounded-[3px] tw-relative projectMobile:tw-max-w-[95%]`}>
        {isValidPrice(minPrice) >= 0 && (
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px] tw-absolute tw-bottom-[13px] tw-left-0">
            <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px] tw-inline-flex tw-gap-[2px]">
              Мин. цена
              <Tooltip title="Минимальная цена за единицу товара среди найденных источников.">
                <TooltipIcon />
              </Tooltip>
            </p>
            <p className="tw-text-[#191919] tw-leading-5 tw-font-medium">
              {priceFormatter(minPrice, 2)}
              <span className="tw-text-sm tw-leading-[16px]">₽</span>
            </p>
          </div>
        )}
        {isValidPrice(avgPrice) && (
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px] tw-absolute tw-bottom-[13px] tw-left-[45%] projectMobile:tw-left-[35%]">
            <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px] tw-inline-flex tw-gap-[2px]">
              Сред. цена
              <Tooltip title="Минимальная цена за единицу товара среди найденных источников.">
                <TooltipIcon />
              </Tooltip>
            </p>
            <p className="tw-text-[#191919] tw-leading-5 tw-font-medium">
              {priceFormatter(avgPrice, 2)}
              <span className="tw-text-sm tw-leading-[16px]">₽</span>
            </p>
          </div>
        )}
        {isValidPrice(maxPrice) && (
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-[7px] tw-absolute tw-bottom-[13px] tw-right-[-31px] projectMobile:tw-right-0">
            <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px] tw-inline-flex tw-gap-[2px]">
              Макс. цена
              <Tooltip title="Минимальная цена за единицу товара среди найденных источников.">
                <TooltipIcon />
              </Tooltip>
            </p>
            <p className="tw-text-[#191919] tw-leading-5 tw-font-medium">
              {priceFormatter(maxPrice, 2)}
              <span className="tw-text-sm tw-leading-[16px]">₽</span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};
