import { Dialog, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import Table from '../../../../components/table';
import { ReactComponent as CloseIcon } from '../../../../icons/closeIcon.svg';
import Button from '../../../../components/button';
import { priceFormatter } from '../../../../utils/priceFormatter';
import { DELIVERY_SERVICES } from '../../../../utils/constant';
import { useMemo } from 'react';
import classNames from 'classnames';
import { Tooltip } from '../../../../components/tooltip/Tooltip';

const columns = {
  delivery_service_id: {
    label: 'Транспортная компания',
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-font-medium tw-leading-[18px] tw-text-sm">
          {DELIVERY_SERVICES[value.delivery_service_id]}
        </p>
      );
    },
  },
  avg_price: {
    label: 'Цена',
    renderBodyCell: (value) => {
      return (
        <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
          {!value?.avg_price ? '-' : priceFormatter(value.avg_price, 2)}
          <span className="tw-ml-1">₽</span>
        </p>
      );
    },
  },
};

export const DeliveryDetails = ({ delivery_info }) => {
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 425px)');

  const validDeliveryPrices = useMemo(
    () => delivery_info.filter((item) => item.avg_price !== 0 && item.price !== null),
    [delivery_info]
  );

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: mobile ? '100%' : '584px',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-gap-6 projectMobile:tw-gap-0 tw-p-6 projectMobile:tw-p-0 tw-bg-white tw-rounded-lg tw-relative">
        <div className="tw-flex tw-flex-row-reverse sm:tw-flex-row tw-justify-between projectMobile:tw-border-b projectMobile:tw-py-4 projectMobile:tw-px-[15px]">
          <Button
            variant="text"
            onClick={() => dialog.close()}
            className="tw-w-fit tw-p-0 hover:tw-bg-transparent  sm:tw-absolute sm:tw-top-[14px] sm:tw-right-[14px]"
          >
            <CloseIcon />
          </Button>
          <p className="tw-text-[#191919] tw-pl-4 tw-text-[18px] tw-font-medium tw-leading-5">Варианты доставки</p>
        </div>
        <div className="projectMobile:tw-hidden">
          <Table rows={validDeliveryPrices} columns={columns} />
        </div>
        <div
          className={classNames('tw-flex tw-flex-col tw-divide-y tw-px-[15px] tw-pb-4', {
            'tw-hidden': !mobile,
          })}
        >
          {validDeliveryPrices.map((delivery) => (
            <div className="tw-flex tw-flex-col tw-pb-[14px] tw-gap-3 tw-pt-4" key={delivery.delivery_service_id}>
              <div className="tw-flex tw-flex-col tw-gap-[2px]">
                <p className="tw-text-xs tw-leading-4 tw-font-medium tw-text-[#666666]">Транспортная компания</p>
                <p className="tw-text-[#191919] tw-font-medium tw-leading-[18px] tw-text-sm">
                  {DELIVERY_SERVICES[delivery.delivery_service_id]}
                </p>
              </div>

              <div className="tw-flex tw-flex-col tw-gap-[2px]">
                <p className="tw-text-xs tw-leading-4 tw-font-medium tw-text-[#666666] tw-flex tw-flex-row tw-items-center tw-gap-1">
                  Цена{' '}
                  <Tooltip
                    title="Цена рассчитана за доставку сборного груза 
(всех товаров проекта)"
                  />
                </p>
                <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
                  {!delivery?.avg_price ? '-' : priceFormatter(delivery.avg_price, 2)}
                  <span className="tw-ml-1">₽</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};
