import { Dialog, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import Input from '../../../components/input';
import ReactQuill from 'react-quill';
import Button from '../../../components/button';
import moment from 'moment';
import styles from './addNews.module.sass';
import DOMPurify from 'dompurify';
import { ReactComponent as ImageIcon } from '../../../icons/imageIcon.svg';
import { ReactComponent as EditIcon } from '../../../icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../icons/deleteIcon.svg';
import { createErrorNotification } from '../../../utils/notifications';
import { useRef } from 'react';
import { IMAGE_MIME_TYPES } from '../../../utils/constant';

export const AddNews = ({ article, onSuccess }) => {
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 500px)');

  const fileInputRef = useRef(null);

  const handleSubmit = async (value) => {
    const article = new FormData();

    Object.entries(value).forEach(([key, value]) => {
      if (key === 'description') {
        article.append(key, DOMPurify.sanitize(value));
      } else if (key === 'image_files' && value) {
        const file = value instanceof Array ? value[0] : value;
        if (file instanceof File) {
          article.append(key, file);
        }
      }else {
        article.append(key, value);
      }
    });
    
    await onSuccess(article).then(() => {
      dialog.close();
    });
  };

  const validate = (values) => {
    const errors = {};
    if (values.title === '' || !values.title) {
      errors.title = 'Обязательно для заполнения';
    }
    if (values.description === '' || values.description === '<p><br></p>' || !values.description) {
      errors.text = 'Обязательно для заполнения';
    }
    if (values?.image_files) {
      const file = values.image_files;

      if (!IMAGE_MIME_TYPES.includes(file?.type) && file instanceof File) {
        errors.file = 'Допустимы только файлы .png .jpeg или .jpg';
        createErrorNotification('Допустимы только файлы .png .jpeg или .jpg');
      }
    }

    return errors;
  };

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '100%' : '632px',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-pt-6 tw-px-8 tw-pb-8 tw-bg-white tw-rounded-xl">
        <h6 className="tw-text-[#191919] tw-text-lg tw-leading-5 tw-font-medium tw-mb-8">
          {!article ? 'Создание новости' : 'Редактирование новости'}
        </h6>
        <Form
          validate={validate}
          onSubmit={handleSubmit}
          initialValues={{
            date: article ? article.date : moment().format('DD.MM.YYYY'),
            title: article && article?.title,
            description: article && article?.description,
            image_files: article?.file_links?.length > 0 ? article.file_links[0] : null,
          }}
        >
          {({ handleSubmit, invalid, pristine }) => (
            <div className="tw-flex tw-flex-col tw-gap-8">
              <Field name="date" render={({ input }) => <Input {...input} className="tw-hidden" />} />
              <Field
                name="title"
                render={({ input }) => (
                  <div className="tw-flex tw-flex-col tw-flex-start tw-w-full tw-gap-3">
                    <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px] tw-font-medium">Заголовок</p>
                    <Input {...input} required />
                  </div>
                )}
              />

              <div className="tw-flex tw-flex-col tw-flex-start tw-w-full tw-gap-3 tw-mb-2" id="text-body">
                <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px] tw-font-medium">Текст</p>
                <Field
                  name="description"
                  render={({ input }) => (
                    <div className="tw-w-full tw-h-full">
                      <ReactQuill
                        {...input}
                        bounds={'#text-body'}
                        className={styles.editor}
                        modules={{
                          toolbar: [
                            ['bold', 'italic', 'underline'],
                            [{ color: [] }],
                            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],

                            ['link'],

                            ['clean'],
                          ],
                        }}
                      />
                    </div>
                  )}
                />
                <Field
                  name="image_files"
                  render={({ input }) => {
                    const handleFileChange = (e) => {
                      const file = e.target.files[0];
                      if (file && !IMAGE_MIME_TYPES.includes(file.type)) {
                        createErrorNotification('Допустимы только файлы .png .jpeg или .jpg');
                        return;
                      }
                      input.onChange(file);
                    };

                    const handleEditClick = () => {
                      fileInputRef?.current?.click();
                    };

                    const handleRemoveFile = () => {
                      input.onChange(null);
                    };

                    const file = input.value;

                    const preview = file instanceof File ? URL.createObjectURL(file) : file;
                    const fileName = file ? file.name : null;
                    return (
                      <div className="tw-mt-6">
                        {!preview ? (
                          <>
                            <label
                              for="uploadBtn"
                              className="tw-text-[#134B98] tw-flex tw-flex-row tw-items-center tw-gap-1  tw-leading-5 tw-font-medium"
                            >
                              <ImageIcon /> Загрузить изображение
                            </label>
                            <input
                              {...input}
                              id="uploadBtn"
                              type="file"
                              hidden
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={handleFileChange}
                            />
                          </>
                        ) : (
                          <div className="tw-flex tw-flex-row tw-items-start tw-gap-[10px]">
                            <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
                              <img
                                src={preview}
                                alt={fileName}
                                className="tw-w-[120px] tw-h-[86px] tw-object-cover tw-rounded-lg"
                              />
                              <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px]">{fileName}</p>
                            </div>
                            <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                              <Button
                                variant="text"
                                onClick={handleEditClick}
                                className="tw-text-blue-600 tw-w-fit tw-p-0"
                              >
                                <EditIcon />
                              </Button>
                              <Button
                                variant="text"
                                onClick={handleRemoveFile}
                                className="tw-text-red-600 tw-w-fit tw-p-0"
                              >
                                <DeleteIcon />
                              </Button>
                            </div>
                            <input
                              ref={fileInputRef}
                              type="file"
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={handleFileChange}
                              hidden
                            />
                          </div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
              <div className="tw-flex tw-flex-row tw-w-full tw-gap-6 tw-justify-end tw-mt-auto">
                <Button
                  variant="text"
                  onClick={() => dialog.close()}
                  className=" tw-w-fit tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold tw-p-0 hover:tw-bg-transparent"
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-leading-[18px] "
                  type="submit"
                  disabled={invalid || pristine}
                  onClick={handleSubmit}
                >
                  {!article ? 'Создать' : 'Сохранить'}
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </Dialog>
  );
};
