import { useCallback, useMemo } from 'react';
import GeneralLayout from '../../components/general-layout';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDeleteArticleMutation, useEditArticleMutation, useGetArticleQuery } from '../../services';
import Button from '../../components/button';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import styles from './news.module.sass';
import { useDialog } from '../../providers/dialog.provider';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import { AddNews } from './modals/AddNews';
import DOMPurify from 'dompurify';
import { createNotification } from '../../utils/notifications';
import { ImageViewer } from './modals/ImageViewer';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import moment from 'moment';

export const NewsDetailed = () => {
  const { id } = useParams();

  const dialog = useDialog();
  const history = useHistory();

  const { data } = useGetArticleQuery(
    {
      id,
    },
    {
      skip: !id,
    }
  );
  const [deleteArticle] = useDeleteArticleMutation();
  const [updateArticle] = useEditArticleMutation();

  const handleEditArticle = useCallback(
    async (body) => {
      await updateArticle({ id, body })
        .unwrap()
        .then(() => {
          createNotification('Новость обновлена');
        });
    },
    [id, updateArticle]
  );

  const handleDeleteArticle = useCallback(
    async (id) => {
      await deleteArticle(id)
        .unwrap()
        .then(() => {
          history.push('/handbook/news');
        });
    },
    [deleteArticle, history]
  );

  const askConfirmationDelete = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal value={entity} title={`Вы уверены, что хотите удалить новость?`} onAgree={handleDeleteArticle} />
      );
    },
    [dialog, handleDeleteArticle]
  );

  const showEditModal = useCallback(() => {
    dialog.open(<AddNews article={data?.news} onSuccess={handleEditArticle} />);
  }, [data?.news, dialog, handleEditArticle]);

  const html = useMemo(() => DOMPurify.sanitize(data?.news?.description), [data?.news?.description]);

  const showImage = useCallback(
    (e) => {
      dialog.open(<ImageViewer src={e.target.src} />);
    },
    [dialog]
  );
  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-min-h-full tw-w-full">
        <h2 className="tw-text-[#191919] tw-font-semibold tw-leading-[38px] tw-text-[32px] md:tw-mb-8 tw-mb-5 sm:tw-mb-7">
          {data?.news?.title}
        </h2>
        <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-min-h-full tw-w-full tw-p-6 tw-gap-5">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-text-sm tw-leading-[18px]">
            <p className="tw-text-[#666666] ">Дата</p>
            <p className="tw-text-[#191919] tw-font-medium">{moment(data?.news.created_at).format('DD.MM.YYYY')}</p>
          </div>
          {data?.news?.file_links.length > 0 && (
            <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-text-sm tw-leading-[18px]">
              <p className="tw-text-[#666666] ">Картинка</p>
              <img
                onClick={showImage}
                src={data?.news?.file_links[0]}
                className="tw-min-w-[220px] tw-max-w-[220px] tw-max-h-[126px] tw-min-h-[126px] tw-rounded-lg"
                alt="article_img"
              />
            </div>
          )}
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-text-sm tw-leading-[18px]">
            <p className="tw-text-[#666666] ">Текст</p>
            <p className={`tw-text-[#191919] ${styles.newsText}`} dangerouslySetInnerHTML={{ __html: html }} />
          </div>
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-[18px]  tw-mt-3">
            <Button
              variant="text"
              onClick={() => showEditModal()}
              className="tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-text-[#134B98] tw-items-center tw-text-sm tw-leading-[18px] tw-font-semibold tw-p-0 hover:tw-bg-transparent"
            >
              <EditIcon />
              Редактировать новость
            </Button>
            <Button
              variant="text"
              className="tw-w-fit tw-text-[#C12026] tw-text-sm tw-leading-[18px] tw-flex tw-flex-row tw-items-center tw-gap-1 tw-font-semibold tw-p-0 hover:tw-bg-transparent"
              onClick={() => askConfirmationDelete(data?.news)}
            >
              <DeleteIcon className="tw-fill-[#C12026]" />
              Удалить новость
            </Button>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
};
