import classNames from 'classnames';
import { BACKGROUNDCOLOR_BY_STATUS } from '../../../utils/constant';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { priceFormatter } from '../../../utils/priceFormatter';
import { getProjectStatus } from '../../../utils/common';
import { ReactComponent as MoreIcon } from '../../../icons/MoreIcon.svg';
import Button from '../../../components/button';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback } from 'react';
import { DropdownModal } from '../../../components/modals/DropdownModal';

export const ProjectsMobile = ({ projects, onEdit, onDelete }) => {
  return (
    <>
      {!projects || projects?.length === 0 ? (
        <p className="tw-text-[#666666] tw-text-sm tw-font-medium tw-leading-[18px]">Проектов нет</p>
      ) : (
        <div className="tw-flex tw-flex-col tw-divide-y tw-divide-[#E6E6E6]">
          {projects?.map((project) => (
            <ProjectsMobileItem project={project} onDelete={onDelete} onEdit={onEdit} />
          ))}
        </div>
      )}
    </>
  );
};

const ProjectsMobileItem = ({ project, onEdit, onDelete }) => {
  const dialog = useDialog();

  const showMobileDropdown = useCallback(() => {
    dialog.open(
      <DropdownModal>
        <div className="tw-flex tw-flex-col tw-rounded-xl tw-items-center tw-divide-y tw-divide-y-[#F7F7F7]">
          <div className="tw-w-[90%]">
            <Button variant="text" onClick={() => onEdit(project)}>
              Редактировать
            </Button>
          </div>
          <div className="tw-w-[90%]">
            <Button variant="text" onClick={() => onDelete(project)}>
              Удалить
            </Button>
          </div>
        </div>
      </DropdownModal>
    );
  }, [dialog, onDelete, onEdit, project]);

  return (
    <div
      className={classNames('tw-relative', {
        'tw-bg-white': !BACKGROUNDCOLOR_BY_STATUS?.[project?.report_status],
        [BACKGROUNDCOLOR_BY_STATUS?.[project?.report_status]]: true,
      })}
    >
      <div className="tw-flex tw-flex-col tw-px-[14px] tw-gap-3 tw-py-4">
        <div className="tw-flex tw-flex-col tw-gap-1">
          <p className="tw-text-[#666666] tw-text-xs tw-font-medium">Название</p>
          <Link className="tw-text-[#134B98] hover:tw-text-[#0A50D4]" to={`/projects/${project.id}`}>
            {project.name}
          </Link>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <p className="tw-text-[#666666] tw-text-xs tw-font-medium">ФИО</p>
          <p className="tw-text-[#134B98] tw-max-w-[234px] tw-truncate tw-text-sm tw-font-medium tw-leading-[18px]">
            {project?.full_name}
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <p className="tw-text-[#666666] tw-text-xs tw-font-medium">Дата создания</p>
          <p className="tw-text-[#191919] tw-text-sm tw-font-medium tw-leading-[18px]">
            {moment(project?.created_at).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <p className="tw-text-[#666666] tw-text-xs tw-font-medium">Цена</p>
          <p className="tw-text-[#191919] tw-text-sm tw-font-medium tw-leading-[18px]">
            {priceFormatter(project?.price, 0)}
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <p className="tw-text-[#666666] tw-text-xs tw-font-medium">Статус</p>
          <div className="tw-flex tw-flex-row tw-justify-start">{getProjectStatus(project)}</div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <p className="tw-text-[#666666] tw-text-xs tw-font-medium">Позиций</p>
          <p className="tw-text-[#191919] tw-text-sm tw-font-medium tw-leading-[18px]">{project?.product_count}</p>
        </div>
      </div>
      <Button
        variant="text"
        className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-absolute tw-right-[14px] tw-top-2"
        onClick={showMobileDropdown}
      >
        <MoreIcon />
      </Button>
    </div>
  );
};
