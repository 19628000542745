import { Checkbox } from '@mui/material';
import { ReactComponent as StarIcon } from '../../../../icons/starIcon.svg';

export const SourceCheckbox = ({ option, handleChange, isSelected, isDisabled }) => {
  return (
    <>
      <Checkbox
        disabled={isDisabled && !isSelected}
        value={option}
        onChange={(e) => handleChange(e, option)}
        className="tw-p-0 tw-mr-2 tw-self-start projectMobile:tw-mr-0 projectMobile:tw-mb-[9px]"
        checked={isSelected}
        icon={<StarIcon className="tw-fill-[#CCCCCC]" />}
        checkedIcon={<StarIcon className="tw-fill-[#DD9F00]" />}
      />
    </>
  );
};
