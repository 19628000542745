import { Dialog } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';

export const DropdownModal = ({ children }) => {
  const dialog = useDialog();

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '90%',
          margin: '0px',
          height: 'fit-content',
          maxHeight: '100%',
          borderRadius: '12px',
        },
      }}
      onClick={() => dialog.close()}
    >
      <div
        className="tw-bg-white tw-flex tw-flex-col tw-rounded-xl tw-divide-y tw-divide-[#F7F7F7]"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </Dialog>
  );
};
