import { Form } from 'react-final-form';
import GeneralLayout from '../../components/general-layout';
import { SearchInput } from '../../components/input/search';
import { useCallback, useEffect, useState } from 'react';
import { userAPIs } from '../../services';
import { OverallInfo } from './components/OverallInfo';
import { Pagination } from '../../components/table/pagination';
import moment from 'moment';
import { useSortableData } from '../../hooks/useSortableData';
import Table from '../../components/table';
import { createErrorNotification } from '../../utils/notifications';
import { DatePicker } from '../../components/date-picker';

export const UsersRequests = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [queryParams, setQueryParams] = useState({
    since: moment().subtract(1, 'month'),
    till: moment(),
  });
  const { requestSort, sortingLabel, sortConfig } = useSortableData();

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (value) => {
    setPageSize(value);
    goToPage(1);
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  // TODO: Rewrite this, this is stupid
  const getQuery = useCallback(() => {
    const search = searchQuery ? `${searchQuery}` : '';
    const query = `&since=${queryParams?.since?.format('DD-MM-YYYY')}&till=${queryParams?.till?.format('DD-MM-YYYY')}`;
    const sortQuery = sortConfig?.key ? `&sort_by=${sortConfig?.key}&order_by=${sortConfig?.direction}` : '';
    return search + query + sortQuery;
  }, [searchQuery, queryParams, sortConfig]);

  const fetchData = useCallback(async () => {
    const query = getQuery();
    await userAPIs
      .getUsersRequests(query)
      .then((res) => setData(() => res))
      .catch((err) => {
        createErrorNotification('Ошибка при получении данных')
    });
  }, [getQuery]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const columns = {
    full_name: {
      label: 'Пользователь',
    },
    total_count: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('total_count')}>
            {sortingLabel('Количество запросов', 'total_count')}
          </div>
        );
      },
    },
    count_projects: {
      renderHeaderCell: () => {
        return (
          <div className="tw-cursor-pointer" onClick={() => requestSort('count_projects')}>
            {sortingLabel('Количество проектов', 'count_projects')}
          </div>
        );
      },
    },
    organization: {
      label: 'Компания'
    },
  };
  return (
    <>
      <GeneralLayout>
        <div className="tw-flex tw-flex-col">
          <h2 className="tw-text-[#191919] tw-text-[32px] tw-leading-[38px] tw-font-semibold tw-mb-8">
            Количество израсходованных запросов
          </h2>
          <div className="tw-flex tw-flex-col tw-bg-[#fff] tw-rounded-lg">
            <div className="tw-flex tw-flex-col gap-3 md:tw-flex-row tw-justify-between tw-items-start md:tw-items-center tw-px-6 tw-pt-[25px] tw-mb-8">
              <Form onSubmit={() => {}}>
                {() => (
                  <>
                    <SearchInput
                      name="search"
                      onSearchChange={handleSearchChange}
                      type="text"
                      placeholder="Найти"
                      className="tw-max-w-[312px]"
                    />
                  </>
                )}
              </Form>
              <div>
                <DatePicker queryParams={queryParams} setQueryParams={setQueryParams} />
              </div>
            </div>
            <div className="tw-px-2 tw-max-h-[616px] tw-overflow-y-auto">
              <Table rows={data?.data} columns={columns} />
            </div>
            <div className="tw-px-[25px] tw-pb-4">
              <OverallInfo
                totalUsers={data?.count_users}
                activeUsersAmount={data?.active_users}
                totalProducts={data?.total_product_count}
                totalProjects={data?.total_project_count}
                totalRequests={data?.total_requests_count}
              />
            </div>
          </div>
          {/* <Pagination
            rowsPerPage={pageSize}
            count={data?.count}
            page={currentPage}
            onPageChange={goToPage}
            onLimitChange={handleLimitChange}
          /> */}
        </div>
      </GeneralLayout>
    </>
  );
};
