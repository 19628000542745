import { createSelector } from '@reduxjs/toolkit';
import { REPORT_SCREENSHOTS_STATUS, REPORT_STATUS, RESTARTABLE_REPORT_STATES } from '../../utils/constant';

const selectedReport = (state) => state?.organizations?.selectedReport;

export const hasAcceptedSelectedChangesSelector = createSelector(
  selectedReport,
  (selectedReport) => selectedReport?.status !== REPORT_STATUS.OPTIMIZED
);

export const getSelectedReportScreenshots = createSelector(
  selectedReport,
  (selectedReport) => selectedReport?.screenshots
);

export const canMakeScreenshots = createSelector(
  selectedReport,
  (selectedReport) =>
    RESTARTABLE_REPORT_STATES.includes(selectedReport?.status) &&
    REPORT_SCREENSHOTS_STATUS.PROCESSING !== selectedReport?.screenshots?.status
);

export const isScreenshotsInProgress = createSelector(
  selectedReport,
  (selectedReport) =>
    selectedReport?.screenshots?.status &&
    (REPORT_SCREENSHOTS_STATUS.INITIAL === selectedReport?.screenshots?.status ||
      REPORT_SCREENSHOTS_STATUS.PROCESSING === selectedReport?.screenshots?.status)
);

export const isReportStatusScreenshoting = createSelector(selectedReport, (selectedReport) => selectedReport?.status === REPORT_STATUS.SCREENSHOOTING)

export const isReportFinished = createSelector(selectedReport, (selectedReport) => selectedReport?.status === REPORT_STATUS.FINISHED)

export const currentReport = createSelector(selectedReport, (selectedReport) => selectedReport)

export const userCanInteractWithProject = createSelector(selectedReport, (selectedReport) => selectedReport?.status !== REPORT_STATUS.SCREENSHOOTING && selectedReport?.status !== REPORT_STATUS.DELIVERY)