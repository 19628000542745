import { Dialog } from '@mui/material';
import { useGetSourceImageQuery } from '../../../../services';
import { useDialog } from '../../../../providers/dialog.provider';
import { ImageWithFallback } from '../../../../components/imageWithFallback';
import Loader from '../../../../components/loader';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

export const SourceImageViewer = ({ project_id, product_id, url_source }) => {
  const dialog = useDialog();
  const [currentImg, setCurrentImage] = useState(null)
  const { data: img, isLoading, isFetching } = useGetSourceImageQuery({
    project_id,
    product_id,
    url_source,
  }, {
    refetchOnMountOrArgChange: true,
    
  });

  useEffect(() => {
    if (isFetching) {
      setCurrentImage(null); 
    } else if (!isLoading && img) {
      setCurrentImage(img); 
    }
  }, [img, isFetching, isLoading])

  return (
    <Dialog
      open={dialog.visibility}
      onClick={() => dialog.close()}
      sx={{
        '& .MuiPaper-root': {
          width: 'fit-content',
          height: 'fit-content',
          maxWidth: 'fit-content',
          margin: '0 16px',
        },
      }}
    >
      <div className={classNames("tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-h-fit tw-w-fit", {
        'tw-w-[400px] tw-h-[400px] tw-items-center tw-justify-center': isLoading || isFetching
      })}>
        {isLoading || isFetching  ? (
          <Loader />
        ) : (
          <ImageWithFallback
            alt="img"
            className="tw-scale-[1]"
            srcImg={currentImg?.link}
            fallbackSrc={'/images/placeholders/other.png'}
          />
        )}
      </div>
    </Dialog>
  );
};
